// File: CustomSquareRenderer.jsx
import React, { forwardRef, useEffect, useState, useRef } from "react";

const CustomSquareRenderer = forwardRef((props, ref) => {
  const { children, style, badge } = props;

  // We'll store which badge is currently being displayed in 'currentBadge',
  // and whether it's visible in 'showBadge'.
  const [currentBadge, setCurrentBadge] = useState(null);
  const [showBadge, setShowBadge] = useState(false);

  const badgeTimerRef = useRef(null);

  useEffect(() => {
    // If the incoming 'badge' is empty or null, we clear everything:
    if (!badge) {
      // Clear any existing timer:
      if (badgeTimerRef.current) clearTimeout(badgeTimerRef.current);
      setCurrentBadge(null);
      setShowBadge(false);
      return;
    }

    // If there's a new badge, we want to show that and only that.
    // 1) Clear the old timer & instantly hide the old badge:
    if (badgeTimerRef.current) clearTimeout(badgeTimerRef.current);
    setShowBadge(false);

    // 2) After a very short delay (~10 ms), set the new badge & show it
    //    so we don't see overlap with the old one.
    const displayNewBadge = setTimeout(() => {
      setCurrentBadge(badge);
      setShowBadge(true);

      // Then start a 2-second timer to fade out the new badge
      badgeTimerRef.current = setTimeout(() => {
        setShowBadge(false);
        badgeTimerRef.current = null;
      }, 2000);
    }, 10);

    // Cleanup
    return () => {
      clearTimeout(displayNewBadge);
    };
  }, [badge]);

  return (
    <div ref={ref} style={{ ...style, position: "relative" }}>
      {children}

      {/* Show the currentBadge only if showBadge==true */}
      {showBadge && currentBadge && (
        <div
          style={{
            position: "absolute",
            top: 2,
            right: 2,
            fontSize: 20,
            zIndex: 9999,
            animation: "fadeBadge 2s linear forwards",
          }}
        >
          {currentBadge}
          <style>
            {`
              @keyframes fadeBadge {
                0% {
                  opacity: 1;
                }
                100% {
                  opacity: 0;
                }
              }
            `}
          </style>
        </div>
      )}
    </div>
  );
});

export default React.memo(CustomSquareRenderer);
