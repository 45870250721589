// File: helpers/chessUtils.js

export const initializeStockfish = (
  stockfish,
  setEvaluation,
  setPvMoves,
  game
) => {
  if (stockfish.current) {
    stockfish.current.terminate();
  }

  const stockfishPath = `${process.env.PUBLIC_URL}/stockfish-nnue-16.js`;
  stockfish.current = new Worker(stockfishPath, { type: "module" });

  stockfish.current.onmessage = (event) => {
    const message = event.data;
    console.log(message);

    if (
      message.includes("info depth") &&
      (message.includes("score cp") || message.includes("score mate"))
    ) {
      const parts = message.split(" ");
      const depthIndex = parts.indexOf("depth");
      let depth = null;
      if (depthIndex !== -1) {
        depth = parts[depthIndex + 1];
      }

      const cpIndex = parts.indexOf("cp");
      const mateIndex = parts.indexOf("mate");
      let score = null;

      if (cpIndex !== -1) {
        score = parseInt(parts[cpIndex + 1], 10) / 100;

        if (game.turn() === "b") {
          score *= -1;
        }
      } else if (mateIndex !== -1) {
        score = parts[mateIndex + 1];
        // Use a high positive or negative number to indicate mate
        if (game.turn() === "b") {
          score = -100;
        } else {
          score = 100;
        }
      }

      if (score !== null) {
        setEvaluation(score);
      }

      const pvIndex = parts.indexOf("pv");
      if (pvIndex !== -1) {
        const pv = parts.slice(pvIndex + 1);
        setPvMoves(pv);
      }
    }
  };

  stockfish.current.postMessage("uci");
};

export const evaluatePositionSequentially = (fen) => {
  return new Promise((resolve) => {
    const stockfishPath = `${process.env.PUBLIC_URL}/stockfish-nnue-16.js`;
    const stockfishWorker = new Worker(stockfishPath, { type: "module" });

    let resolved = false;
    let latestScore = null; // To store the latest score received
    let latestMate = null; // To store the latest mate evaluation

    const handleStockfishMessage = (event) => {
      const message = event.data;
      console.log("Stockfish message:", message);

      if (message.includes("info")) {
        const parts = message.split(" ");
        const cpIndex = parts.indexOf("cp");
        const mateIndex = parts.indexOf("mate");

        if (cpIndex !== -1) {
          latestScore = parseInt(parts[cpIndex + 1], 10) / 100;
        } else if (mateIndex !== -1) {
          latestMate = parseInt(parts[mateIndex + 1], 10);
        }
      }
    };

    stockfishWorker.addEventListener("message", handleStockfishMessage);

    stockfishWorker.postMessage("uci");
    stockfishWorker.postMessage(`position fen ${fen}`);
    stockfishWorker.postMessage("go movetime 500"); // Set Stockfish to search for 1.5 seconds

    setTimeout(() => {
      if (!resolved) {
        resolved = true;

        // Choose the last evaluation available
        let finalScore = 0;
        if (latestMate !== null) {
          finalScore = latestMate > 0 ? 100 : -100; // Assign a high score for mate
        } else if (latestScore !== null) {
          finalScore = latestScore; // Use the most recent score
        }

        stockfishWorker.removeEventListener("message", handleStockfishMessage);
        stockfishWorker.terminate();
        resolve(finalScore);
      }
    }, 1000); // Ensure the timeout slightly exceeds the search time
  });
};

export const getBestLineFromStockfish = (fen) => {
  return new Promise((resolve) => {
    const stockfishPath = `${process.env.PUBLIC_URL}/stockfish-nnue-16.js`;
    const stockfishWorker = new Worker(stockfishPath, { type: "module" });

    let resolved = false;
    let messages = [];

    const handleStockfishMessage = (event) => {
      const message = event.data;
      console.log("Stockfish message:", message);

      messages.push(message);
    };

    stockfishWorker.addEventListener("message", handleStockfishMessage);

    stockfishWorker.postMessage("uci");
    stockfishWorker.postMessage(`position fen ${fen}`);
    stockfishWorker.postMessage("go movetime 500"); // Run analysis for 3 seconds

    setTimeout(() => {
      if (!resolved) {
        resolved = true;

        stockfishWorker.removeEventListener("message", handleStockfishMessage);
        stockfishWorker.terminate();

        // Process messages to find the PV line from the highest depth
        let bestDepth = -1;
        let bestPV = [];

        for (let msg of messages) {
          if (msg.includes("info depth") && msg.includes(" pv ")) {
            const parts = msg.split(" ");
            const depthIndex = parts.indexOf("depth");
            const pvIndex = parts.indexOf("pv");

            if (depthIndex !== -1 && pvIndex !== -1) {
              const depth = parseInt(parts[depthIndex + 1], 10);
              const pv = parts.slice(pvIndex + 1);

              if (depth > bestDepth) {
                bestDepth = depth;
                bestPV = pv;
              }
            }
          }
        }

        resolve(bestPV);
      }
    }, 1000); // Slightly longer than the movetime to ensure completion
  });
};

/**
 * New Function: getBestMove
 * Extracts the best move from the principal variation provided by Stockfish.
 */
export const getBestMove = async (fen) => {
  const bestLine = await getBestLineFromStockfish(fen);
  if (bestLine.length === 0) {
    return null;
  }
  const bestMove = bestLine[0];
  return {
    from: bestMove.substring(0, 2),
    to: bestMove.substring(2, 4),
    promotion: bestMove.length > 4 ? bestMove[4] : undefined,
  };
};
