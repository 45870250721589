import {
  Box,
  Link,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Textarea,
  useToast,
  Stack,
  VStack,
  Center,
  Image,
  Progress,
  Icon,
  Spinner,
  Checkbox,
} from "@chakra-ui/react";

import {
  MdMenu,
  MdSettings,
  MdFavorite,
  MdFileUpload,
  MdLightbulbOutline,
  MdRefresh,
  MdArrowUpward,
} from "react-icons/md";

const AboutBody = () => {
  return (
    <div>
      A wonderful new &amp; modern site that lets you review famous Chess games
      and guess the moves the masters have played from start to finish. Great
      for training a specific playstyle, or enjoying the show in general. No
      matter what your rating, you can benefit from and enjoy the classic games
      available. <br /> <br />
      Some uses for the site:
      <br />
      - Train a specific playstyle, aggressive, positional, defensive, you name
      it. It's awesome to play the game in the perspective of the person you're
      trying to learn from.
      <br /> - Memorize a famous game and impress your friends :)
      <br />- Sit back and watch high quality and famous GM games with autoplay
      button.
      <br />- Coaches can give interactive lessons with their students.
      <br />
      <br />
      Developed solo by a ~2100 rated player Leon Fresh:{" "}
      <Link isExternal color="blue.400" href="https://lichess.org/@/LeonFresh">
        https://lichess.org/@/LeonFresh
      </Link>
      <br />
      <br /> My aim is to further enhance your appreciation for the game of
      Chess and bring more people to the game, while also keeping this site{" "}
      <b>completely, absolutely, 100% free</b>. Will also keep adding new
      features based on community feedback and fix any bugs that you may
      encounter. However, this won't be possible without your support.
      <br />
      <br />
      If you enjoy using this site, any donation will go a long way to support
      this journey and improve the site for all lovers of Chess! <br />
      <br /> Please consider donating any amount you wish: &nbsp;&nbsp;&nbsp;
      <Link isExternal href="https://opencollective.com/moveguesser">
        <Button
          leftIcon={<Icon color="red.400" as={MdFavorite} />}
          variant="solid"
        >
          <Text color="black" fontSize="15">
            Sponsor
          </Text>
        </Button>
      </Link>
      <br />
      <br />
      Another way to help is by using the profitable trading algorithms (crypto
      bots) I have developed, via this{" "}
      <Link
        color="blue.400"
        href="https://app.tuned.com/t/LunarClub"
        isExternal
      >
        link
      </Link>
      .
      <br />
      <br />
      {/* Tons of exciting new features are planned for the future:
        <br />
        <br /> */}
      <strong>
        <span fontSize="18px">Phase One Roadmap</span>{" "}
      </strong>
      <br />
      <br />
      <b>$0 out of $5000</b> donations received, amount updated weekly
      <Progress hasStripe value={1} />
      <br /> <b>$500:</b> Initial release, testing and bug fixes. Better
      mobile-responsiveness.
      <br />
      <br /> <b>$1000:</b> Scoring features, scores how well you do and saves
      them, for you to improve on.
      <br />
      <br />
      <b>$2000:</b> More quality curated collection of Chess games added to the
      database.
      <br />
      <br /> <b>$3000:</b> Move list with annotations
      <br />
      <br /> <b>$5000:</b> Huge membership update -- features that lets you save
      your favourite games, automatically detect and show games that you had
      trouble guessing, social features such as commenting, games tagging,
      submit your own games, or create collections for others to enjoy.
      <br />
      And yes it will be 100% free. <br />
      <br /> And much more, join our{" "}
      <Link color="blue.400" isExternal href="https://discord.gg/2wxGbeagq3">
        Discord
      </Link>{" "}
      to suggest more! Stay up to date with the latest updates and provide
      feedback / suggestions or chat with the developer via Discord. View our{" "}
      <Link
        color="blue.400"
        isExternal
        href="https://trello.com/b/MsHEi7Gt/moveguesser"
      >
        Trello
      </Link>{" "}
      to see what we are working on currently. <br />
      <br />
      <b>Special Thanks:</b> This site wouldn't be possible without the help of
      Pawel who made{" "}
      <Link color="blue.400" isExternal href="https://chessvision.ai">
        ChessVision.ai
      </Link>
      .
      <br />
      <Link
        color="blue.400"
        href="https://github.com/ornicar/chessground"
        isExternal
      >
        Chessground
      </Link>
      <br />
      <Link color="blue.400" href="https://stockfishchess.org/" isExternal>
        Stockfish
      </Link>
    </div>
  );
};
export default AboutBody;
