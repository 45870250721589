// File: Main.js

import React, { useState, useEffect, useRef } from "react";
import useStateRef from "react-usestateref";
import Chess from "chess.js";
import { Chessboard } from "react-chessboard";
import { SimpleGrid } from "@chakra-ui/react";
import queen from "./images/wQ.svg";
import rook from "./images/wR.svg";
import bishop from "./images/wB.svg";
import knight from "./images/wN.svg";

import CustomModal from "./functions/CustomModal";
import GameListModal from "./functions/GameListModal";
import games from "./games/games.txt";
import Games from "./games/Games";
import pgnParser from "./functions/pgnParser";
import Helper from "./functions/Helper";
import AboutBody from "./functions/AboutBody";
import {
  Box,
  Link,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Textarea,
  useToast,
  Stack,
  VStack,
  Center,
  Image,
  Spinner,
  Checkbox,
  Icon,
} from "@chakra-ui/react";

import {
  MdMenu,
  MdFavorite,
  MdFileUpload,
  MdArrowUpward,
} from "react-icons/md";
import { BiShuffle } from "react-icons/bi";
import { FaLightbulb } from "react-icons/fa";
import { HiSwitchVertical } from "react-icons/hi";

import { createBreakpoints } from "@chakra-ui/theme-tools";

// =====================
// IMPORT from chessUtils
// =====================
import { evaluatePositionSequentially } from "./helpers/chessUtils";

// ===== Import the updated CustomSquareRenderer =====
import CustomSquareRenderer from "./functions/CustomSquareRenderer";

const Main = () => {
  const [chess, setChess] = useState(new Chess());
  let pgn = [
    '[Event "Casual Game"]',
    '[Site "Berlin GER"]',
    '[Date "1852.??.??"]',
    '[EventDate "?"]',
    '[Round "?"]',
    '[Result "1-0"]',
    '[White "Adolf Anderssen"]',
    '[Black "Jean Dufresne"]',
    '[ECO "C52"]',
    '[WhiteElo "?"]',
    '[BlackElo "?"]',
    '[PlyCount "47"]',
    "",
    "1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.b4 Bxb4 5.c3 Ba5 6.d4 exd4 7.O-O",
    "d3 8.Qb3 Qf6 9.e5 Qg6 10.Re1 Nge7 11.Ba3 b5 12.Qxb5 Rb8 13.Qa4",
    "Bb6 14.Nbd2 Bb7 15.Ne4 Qf5 16.Bxd3 Qh5 17.Nf6+ gxf6 18.exf6",
    "Rg8 19.Rad1 Qxf3 20.Rxe7+ Nxe7 21.Qxd7+ Kkd7 22.Bf5+ Ke8",
    "23.Bd7+ Kf8 24.Bxe7# 1-0",
  ];
  const [moves, setMoves, movesRef] = useStateRef([]);
  const [movesVerbose, setMovesVerbose, movesVerboseRef] = useStateRef([]);
  const [currentTurn, setCurrentTurn, currentTurnRef] = useStateRef(0);
  const [pendingMove, setPendingMove] = useState();
  const [selectVisible, setSelectVisible] = useState(false);
  const [fen, setFen] = useState("");
  const [lastMove, setLastMove] = useState();
  const [hint, setHint] = useState();

  const [libraryDb, setLibraryDb] = useState([]);
  const [libraryGameTitles, setLibraryGameTitles] = useState([]);

  const [gamesDb, setGamesDb] = useState([]);
  const [gameTitle, setGameTitle] = useState("...");

  const [boardOrientation, setBoardOrientation] = useState(false);

  const [pgnWinner, setPgnWinner, pgnWinnerRef] = useStateRef("white");
  const [currentPlayerTurn, setCurrentPlayerTurn] = useState(false);

  // "w" => user guesses White's moves; "b" => user guesses Black's moves
  const [playerColor, setPlayerColor] = useState("w");

  const [evalText, setEvalText] = useState("...");
  const [whiteName, setWhiteName] = useState("");
  const [blackName, setBlackName] = useState("");
  const [date, setDate] = useState("");
  const [whiteElo, setWhiteElo] = useState(0);
  const [blackElo, setBlackElo] = useState(0);

  const [wrongMoveCount, setWrongMoveCount, wrongMoveCountRef] = useStateRef(0);
  const [autoplay, setAutoplay, autoplayRef] = useStateRef(false);
  const [skipping, setSkipping, skippingRef] = useStateRef(false);

  const [moveCount, setMoveCount, moveCountRef] = useStateRef(0);

  // We'll store a persistent "currentEval" for user moves
  const [currentEval, setCurrentEval, currentEvalRef] = useStateRef(0.3);

  const [totalMoveScores, setTotalMoveScores, totalMoveScoresRef] =
    useStateRef(0);
  const [comboText, setComboText, comboTextRef] = useStateRef(0);
  const [scoreText, setScoreText, scoreTextRef] = useStateRef(0);
  const [accuracyText, setAccuracyText, accuracyTextRef] = useStateRef(100);

  const [useEngine, setUseEngine, useEngineRef] = useStateRef(true);
  const [isEvaluating, setIsEvaluating, isEvaluatingRef] = useStateRef(true);

  const [gameMenuState, setGameMenuState] = useState("library");

  // track if the user wants to skip the opening
  const [skipOpeningVisible, setSkipOpeningVisible] = useState(true);

  // Badge for the last move (single emoji)
  const [lastMoveBadge, setLastMoveBadge] = useState(null);

  const breakpoints = createBreakpoints({
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
  });

  const toast = useToast();
  const toastIdRef = useRef();

  // For measuring material from a specific side (optional)
  const getMaterialForSide = (fen, color) => {
    const pieceVals = { p: 1, n: 3, b: 3, r: 5, q: 9, k: 0 };
    const tmpChess = new Chess(fen);
    let total = 0;
    tmpChess.SQUARES.forEach((sq) => {
      const piece = tmpChess.get(sq);
      if (piece && piece.color === color) {
        total += pieceVals[piece.type] || 0;
      }
    });
    return total;
  };

  useEffect(() => {
    document.body.style = "background: rgb(41 53 60 / 96%);";
    initPgnDb();

    const handle = setInterval(() => tick(), 2000);
    return () => clearInterval(handle);
    // eslint-disable-next-line
  }, []);

  const tick = () => {
    if (movesRef.current.length <= currentTurnRef.current) {
      setAutoplay(false);
    } else if (autoplayRef.current) {
      forward();
    }
  };

  // Load PGN database (same logic as before)
  const initPgnDb = async () => {
    let overallGames = [];
    let allGameTitles = [];

    for (let i = 0; i < Games.GetCount(); i++) {
      const title = Games.GetName(i);
      allGameTitles.push(title);

      const r = await fetch(Games.LoadGame(i));
      const db = await r.text();

      let lines = db.split("\n");
      let newFile = "";
      let count = 0;

      for (let j in lines) {
        if (lines[j].match(/^\s*$/)) {
          count += 1;
        }
        if (count >= 2) {
          count = 0;
          newFile += "+_)(*&^%$#@!~\n";
        } else if (count !== -1) {
          newFile += lines[j] + "\n";
        }
      }
      let dbSplit = newFile.split("+_)(*&^%$#@!~");
      let finalGames = [];

      for (let j in dbSplit) {
        let pgnSplit = dbSplit[j].split("\n");
        let k = pgnSplit.length;
        while (k--) {
          pgnSplit[k] = pgnSplit[k].replace("\r", "");
          if (pgnSplit[k] === "") {
            pgnSplit.splice(k, 1);
          }
        }
        finalGames.push(pgnSplit);
        if (overallGames[i] === undefined) {
          overallGames[i] = [];
        }
        overallGames[i].push(pgnSplit);
      }
    }

    setLibraryDb(overallGames);
    setLibraryGameTitles(allGameTitles);
    loadRandomPgn(overallGames);
  };

  const loadRandomPgn = (overallGames) => {
    let randDb = overallGames[Math.floor(Math.random() * overallGames.length)];
    let randGame = randDb[Math.floor(Math.random() * randDb.length)];
    setGamesDb(randDb);
    setGameTitle(randGame[0].replace("[", "").replace("]", ""));
    pgn = randGame;
    loadPgn();
  };

  const loadPgn = () => {
    setAutoplay(false);
    setGameTitle(pgn[0].replace("[", "").replace("]", ""));
    chess.load_pgn(pgn.join("\n"));
    setMoves(chess.history());
    setMovesVerbose(chess.history({ verbose: true }));

    let a = pgnParser(pgn);
    setPgnWinner(a.winner);
    setDate(a.date);
    setBlackName(a.blackName);
    setWhiteName(a.whiteName);
    setBlackElo(a.blackElo > 0 ? a.blackElo : " ");
    setWhiteElo(a.whiteElo > 0 ? a.whiteElo : " ");

    // Reset
    setSkipOpeningVisible(true);

    if (pgnWinnerRef.current === "black") {
      setBoardOrientation(true);
      setTimeout(() => {
        forward();
      }, 500);
    } else {
      setBoardOrientation(false);
    }

    reset();
    setEvalText("+0.2");
  };

  const PlaySound = (soundName) => {
    let soundFile = "sounds/" + soundName + ".mp3";
    if (soundFile) {
      const audio = new Audio(soundFile);
      audio.play();
    }
  };

  /**
   * EvaluateMove => calls Stockfish, picks an emoji badge, sets lastMoveBadge
   */
  const EvaluateMove = async (
    fenBeforeMove,
    fenAfterMove,
    isWrongMove,
    playSound,
    doNotShowPopup,
    goToNextMove,
    skipThreshold = false
  ) => {
    setIsEvaluating(true);

    let rawEval = await evaluatePositionSequentially(fenAfterMove);
    const nextTurn = new Chess(fenAfterMove).turn();
    // If nextTurn === 'w', that means black just moved => invert
    if (nextTurn === "w") {
      rawEval = -rawEval;
    }

    if (skipThreshold) {
      setCurrentEval(rawEval);
      setIsEvaluating(false);
      if (goToNextMove) {
        forwardManual(null, null, true);
      }
      return;
    }

    const oldEval = currentEvalRef.current;
    const difference = rawEval - oldEval;

    // pick emoji
    let badgeEmoji = "";
    let toastString = "";
    let customStatus = "info";
    let soundText = "";

    // Simple mate logic
    if (rawEval >= 100) {
      badgeEmoji = "🔥";
      toastString = "Mate (Winning)";
      customStatus = "success";
      soundText = "best";
    } else if (rawEval <= -100) {
      badgeEmoji = "❌";
      toastString = "Mate (Losing)";
      customStatus = "error";
      soundText = "blunder";
    } else {
      const absDiff = Math.abs(difference);
      if (absDiff >= 2.0) {
        badgeEmoji = "❌"; // blunder
        toastString = "Blunder";
        customStatus = "error";
        soundText = "blunder";
      } else if (absDiff >= 1.0) {
        badgeEmoji = "❓"; // mistake
        toastString = "Mistake";
        customStatus = "warning";
        soundText = "mistake";
      } else if (absDiff >= 0.5) {
        badgeEmoji = "⚠️"; // inaccuracy
        toastString = "Inaccuracy";
        customStatus = "warning";
        soundText = "inaccuracy";
      } else if (absDiff >= 0.2) {
        badgeEmoji = "✅"; // good
        toastString = "Good";
        customStatus = "info";
        soundText = "good";
      } else {
        badgeEmoji = "🔥"; // excellent
        toastString = "Excellent";
        customStatus = "success";
        soundText = "best";
      }
    }

    // optional sound
    if (playSound) {
      PlaySound(soundText);
    }

    setCurrentEval(rawEval);
    setIsEvaluating(false);

    if (!doNotShowPopup) {
      setLastMoveBadge(badgeEmoji);
    } else {
      setLastMoveBadge(null);
    }

    // Show toast if not skipping
    if (!doNotShowPopup) {
      if (isWrongMove && (badgeEmoji === "🔥" || badgeEmoji === "✅")) {
        // Good or excellent, but not in PGN => skip?
        toast({
          duration: 5000,
          position: "top",
          isClosable: true,
          render: () => (
            <Box
              color="white"
              p={3}
              bg="blue.600"
              borderRadius="md"
              boxShadow="md"
            >
              <Text fontWeight="bold" mb={1}>
                {toastString}
              </Text>
              <Text mb={2}>
                Eval changed by {difference.toFixed(2)} (from{" "}
                {oldEval.toFixed(2)} to {rawEval.toFixed(2)}) — You made a good
                move, but it’s not in the PGN. Want to skip?
              </Text>
              <Button
                colorScheme="yellow"
                size="sm"
                onClick={() => {
                  skipThisMove();
                }}
              >
                Skip Move
              </Button>
            </Box>
          ),
        });
      } else {
        // normal toast
        toast({
          title: toastString,
          description: `Eval changed by ${difference.toFixed(
            2
          )} (from ${oldEval.toFixed(2)} to ${rawEval.toFixed(2)})`,
          status: customStatus,
          duration: 3000,
          position: "top",
          isClosable: true,
        });
      }
    }

    if (goToNextMove) {
      forwardManual(null, null, true);
    }
  };

  // Skip user move => skip 2 plies
  const skipThisMove = async () => {
    if (movesRef.current.length > currentTurnRef.current) {
      forward();
    }
    if (movesRef.current.length > currentTurnRef.current) {
      forward();
    }
    let fenNow = chess.fen();
    await EvaluateMove(fenNow, fenNow, false, false, true, false, true);
  };

  // Skip opening => first 5 moves (10 plies)
  const skipOpening = async () => {
    setSkipOpeningVisible(false);
    for (let i = 0; i < 10; i++) {
      if (movesRef.current.length > currentTurnRef.current) {
        await new Promise((res) => setTimeout(res, 1000));
        forward();
      } else {
        break;
      }
    }
    let fenNow = chess.fen();
    await EvaluateMove(fenNow, fenNow, false, false, true, false, true);
  };

  const switchToWhite = async () => {
    if (playerColor !== "w") {
      setPlayerColor("w");
      if (chess.turn() === "w") {
      } else {
        if (movesRef.current.length > currentTurnRef.current) {
          forward();
          setBoardOrientation(!boardOrientation);
        }
      }
    }
  };

  const switchToBlack = async () => {
    if (playerColor !== "b") {
      setPlayerColor("b");
      if (chess.turn() === "b") {
      } else {
        if (movesRef.current.length > currentTurnRef.current) {
          forward();
          setBoardOrientation(!boardOrientation);
        }
      }
    }
  };

  // ----------------------------
  // Main user move handler
  // ----------------------------
  const onMove = async (from, to) => {
    const fenBeforeMove = chess.fen();
    const oldEval = currentEvalRef.current;

    // Attempt user’s move
    const userAttempt = chess.move({ from, to, promotion: "q" });
    if (userAttempt == null) {
      // invalid => do nothing
      return;
    }

    // 1) Immediately store the player's "to" square for the badge
    setLastMove([from, to]);

    setHint(null);
    setFen(chess.fen());

    // Check if it’s correct per PGN
    const correctFrom = movesVerboseRef.current[currentTurnRef.current]?.from;
    const correctTo = movesVerboseRef.current[currentTurnRef.current]?.to;

    // If correct => Evaluate => then do opponent's next move (no overwriting lastMove)
    if (from === correctFrom && to === correctTo) {
      const verboseObj = movesVerboseRef.current[currentTurnRef.current];
      if (
        verboseObj &&
        verboseObj.flags.includes("p") &&
        verboseObj.promotion
      ) {
        // show promotion UI
        setPendingMove([from, to]);
        setSelectVisible(true);
        return;
      }

      const fenAfterUser = chess.fen();
      checkEndOfGame(1);

      await EvaluateMove(
        fenBeforeMove,
        fenAfterUser,
        false,
        true,
        false,
        false,
        false
      );

      // Opponent’s move (no overwriting lastMove => keep user’s to-square)
      if (movesRef.current.length > currentTurnRef.current + 1) {
        const oppVerbose = movesVerboseRef.current[currentTurnRef.current + 1];
        if (oppVerbose) {
          const fenBeforeOpp = chess.fen();

          chess.move(oppVerbose);
          // do NOT call setLastMove here => keep the user’s to-square
          setFen(chess.fen());
          setCurrentTurn(currentTurnRef.current + 2);
          checkEndOfGame();

          // Evaluate opponent’s move (skip badge)
          const fenAfterOpp = chess.fen();
          await EvaluateMove(
            fenBeforeOpp,
            fenAfterOpp,
            false,
            false,
            true, // doNotShowPopup => true => no new badge
            false,
            true
          );
        }
      }
    } else {
      // Wrong => measure difference => show badge => then undo
      const fenAfterWrong = chess.fen();

      setTimeout(async () => {
        await EvaluateMove(
          fenBeforeMove,
          fenAfterWrong,
          true, // isWrongMove
          true, // playSound
          false, // doNotShowPopup
          false,
          false
        );

        // undo
        chess.undo();
        setFen(chess.fen());
        setCurrentEval(oldEval);

        // Keep lastMove on the user’s attempted square
      }, 300);
    }
  };

  // For promotion
  const promotion = (piece) => {
    const from = pendingMove[0];
    const to = pendingMove[1];
    const correctPromotion = movesVerbose[currentTurnRef.current].promotion;

    if (correctPromotion === piece) {
      chess.move({ from, to, promotion: piece });
      setFen(chess.fen());
      // keep lastMove => [from, to]
      setSelectVisible(false);
      setTimeout(() => forwardManual(from, to), 500);
    } else {
      // wrong promotion => measure difference => then undo
      const fenBeforeWrongPromotion = chess.fen();
      chess.move({ from, to, promotion: piece });
      chess.undo();
      EvaluateMove(fenBeforeWrongPromotion, chess.fen(), true, true);
    }
  };

  const orientation = () => boardOrientation;

  // Single-ply forward
  const forward = (crunch, doNotShowPopup) => {
    if (movesRef.current.length < currentTurnRef.current + 1) return;
    const move = movesRef.current[currentTurnRef.current];
    const verboseMove = movesVerboseRef.current[currentTurnRef.current];
    chess.move(move);
    // If user manually steps forward => we overwrite lastMove
    setLastMove([verboseMove.from, verboseMove.to]);
    setFen(chess.fen());
    setCurrentTurn(currentTurnRef.current + 1);
    checkEndOfGame();
  };

  // 2 plies => user’s move + black’s response
  const forwardManual = async (from, to, crunch) => {
    if (movesRef.current.length < currentTurnRef.current + 1) return;

    if (
      currentTurnRef.current < movesRef.current.length &&
      from &&
      to &&
      movesRef.current[currentTurnRef.current]?.includes(`${from}${to}`)
    ) {
      chess.move(movesRef.current[currentTurnRef.current]);
      setFen(chess.fen());
      // user’s last move => [from, to]
      setLastMove([from, to]);
      setCurrentTurn(currentTurnRef.current + 1);
      checkEndOfGame();
    }

    if (movesRef.current.length < currentTurnRef.current + 1) return;

    const blackMove = movesRef.current[currentTurnRef.current];
    if (blackMove) {
      const verboseMove = movesVerboseRef.current[currentTurnRef.current];
      chess.move(blackMove);
      setFen(chess.fen());
      // do NOT overwrite lastMove => keep user’s
      setCurrentTurn(currentTurnRef.current + 1);
      checkEndOfGame();
    }
  };

  const backward = () => {
    if (currentTurnRef.current === 0) return;
    chess.undo();
    setFen(chess.fen());
    setCurrentTurn(currentTurnRef.current - 1);

    if (currentTurnRef.current === 0) {
      setLastMove([]);
      return;
    }
    setLastMove([
      movesVerbose[currentTurnRef.current - 1].from,
      movesVerbose[currentTurnRef.current - 1].to,
    ]);
  };

  const randomMove = () => {
    const possible = chess.moves({ verbose: true });
    if (!possible.length) return;
    const mv = possible[Math.floor(Math.random() * possible.length)];
    chess.move(mv.san);
    setFen(chess.fen());
    setLastMove([mv.from, mv.to]);
  };

  const checkEndOfGame = (offset = 0) => {
    if (movesRef.current.length <= currentTurnRef.current + offset) {
      const convertAccuracyToRating = (accuracy) => {
        if (accuracy < 0 || accuracy > 100) {
          throw new Error("Accuracy must be between 0% and 100%.");
        }
        if (accuracy <= 50) {
          return (accuracy / 50) * 1000;
        } else if (accuracy <= 90) {
          return 1000 + ((accuracy - 50) / 40) * 1300;
        } else {
          return 2300 + ((accuracy - 90) / 10) * 700;
        }
      };

      toast({
        title: "End of game.",
        description:
          "🎉 Congratulations! Your rating for this game is " +
          convertAccuracyToRating(accuracyTextRef.current).toFixed(0) +
          " ELO and your final score is: " +
          scoreTextRef.current,
        status: "success",
        duration: 30000,
        position: "top",
        isClosable: true,
      });
    }
  };

  // Reset board to initial
  const reset = () => {
    chess.reset();
    setFen(chess.fen());
    setCurrentTurn(0);
    setLastMove([]);
    setEvalText("+0.3");
    setWrongMoveCount(0);
    setComboText(0);
    setSkipping(false);
    setMoveCount(0);
    setTotalMoveScores(0);
    setScoreText(0);
    setAccuracyText(100);
    setIsEvaluating(false);
    setCurrentEval(0.3);

    setLastMoveBadge(null);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenGameList,
    onOpen: onOpenGameList,
    onClose: onCloseGameList,
  } = useDisclosure();

  let [pgnValue, setPgnValue] = useState("");
  let handlePgnInputChange = (e) => {
    let inputValue = e.target.value;
    setPgnValue(inputValue);
  };

  const [loadAsBlack, setLoadAsBlack] = useState(false);
  const loadPgnBtn = () => {
    pgn = pgnValue.split("\n");
    loadPgn();
    onClose();
    reset();
    if (loadAsBlack) {
      setBoardOrientation(true);
      setTimeout(() => {
        forward();
      }, 500);
    }
  };

  const gameListRef = useRef();
  const loadGame = (gameNumber) => {
    pgn = gamesDb[gameNumber];
    loadPgn();
    onCloseGameList();
  };

  const loadGameList = (int) => {
    setGameMenuState("gameList");
    setGamesDb(libraryDb[int]);
  };

  const onClickBackToLibrary = () => {
    setGameMenuState("library");
  };

  const onShuffleClick = () => {
    loadRandomPgn(libraryDb);
  };

  const loadPgnRef = useRef();

  // highlight squares for the last move
  const customSquareStyles = {};
  if (lastMove) {
    customSquareStyles[lastMove[0]] = {
      backgroundColor: "rgba(175, 242, 104,0.5)",
    };
    customSquareStyles[lastMove[1]] = {
      backgroundColor: "rgba(175, 242, 104,0.5)",
    };
  }
  if (hint) {
    customSquareStyles[hint] = {
      backgroundColor: "lightblue",
    };
  }

  // customSquare callback => show badge if square === lastMove[1]
  const customSquare = (squareProps) => {
    const { square } = squareProps;
    if (lastMove && square === lastMove[1]) {
      return (
        <CustomSquareRenderer
          {...squareProps}
          badge={lastMoveBadge} // single emoji
        />
      );
    }
    return <CustomSquareRenderer {...squareProps} />;
  };

  if (blackName === "") {
    return (
      <VStack width="100vw" height="100vh">
        <Center>
          <Spinner
            position="absolute"
            top="0"
            bottom="0"
            marginTop="auto"
            marginBottom="auto"
            size="xl"
            color="white"
          />
        </Center>
      </VStack>
    );
  }

  return (
    <div>
      {/* HEADER */}
      <Box
        id="header"
        pt={{ base: 18, md: 27 }}
        width="100%"
        background="#151d4e"
        height={{ base: "130px", md: "90px" }}
        pb={{ base: -3, md: 0 }}
      >
        <Stack
          ml={25}
          direction="row"
          spacing={{ base: 0, md: 4 }}
          center="true"
          float="left"
        >
          <Link textDecoration="none" src="/">
            <Image
              onClick={() => onShuffleClick()}
              src="images/logo-moveguesser.png"
              float="left"
              width="48px"
              mr={2}
              mt={-1}
            />
            <Text
              onClick={() => onShuffleClick()}
              fontWeight="bold"
              fontSize="x-large"
              color="white"
              float="left"
            >
              MoveGuesser.com
            </Text>
          </Link>
        </Stack>

        <Stack
          mr={30}
          direction="row"
          transform={{ base: "scale(0.9)", md: "scale(1)" }}
          spacing={{ base: 3, md: 4 }}
          center="true"
          mt={{ base: 4, md: 0 }}
          ml={{ base: 0, md: 0 }}
          float={{ base: "left", md: "right" }}
        >
          <CustomModal
            showModalButtonText="About"
            modalHeader="About"
            primaryBtnText="Join our Discord"
            modalBody={<AboutBody />}
          />

          <Button
            colorScheme="yellow"
            leftIcon={<Icon as={MdMenu} />}
            mr={2}
            onClick={onOpenGameList}
          >
            <Text fontSize="15px">Game List</Text>
          </Button>

          <Modal
            ref={gameListRef}
            colorScheme="whiteAlpha"
            scrollBehavior="inside"
            isOpen={isOpenGameList}
            onClose={onCloseGameList}
          >
            <ModalOverlay />
            <ModalContent background="#1f2225" color="#d2d2d2" maxWidth="80vw">
              <ModalHeader>
                <Text float="left">
                  {gameMenuState === "library" ? "Library" : "Game List"}
                </Text>
                <Button
                  leftIcon={<Icon as={MdArrowUpward} />}
                  colorScheme="blue"
                  ml={5}
                  size="sm"
                  float="left"
                  onClick={onClickBackToLibrary}
                  display={gameMenuState === "library" ? "none" : "block"}
                >
                  Back to Library
                </Button>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody
                display="grid"
                gridTemplateColumns={{ base: "1fr 1fr", lg: "1fr 1fr 1fr" }}
                gridAutoFlow="row dense"
                gridGap="5px"
                boxSizing="border-box"
              >
                {gameMenuState === "gameList"
                  ? gamesDb.map((item, i) => (
                      <Box onClick={() => loadGame(i)} p={2} key={i}>
                        <Text cursor="pointer">
                          <strong>
                            {i + 1}. {item[0].replace("[", "").replace("]", "")}
                          </strong>
                        </Text>
                      </Box>
                    ))
                  : libraryGameTitles.map((item, i) => (
                      <Box onClick={() => loadGameList(i)} p={2} key={i}>
                        <Text cursor="pointer">
                          <strong>{item + "/"}</strong>
                        </Text>
                      </Box>
                    ))}
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" onClick={onCloseGameList}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Link isExternal href="https://opencollective.com/moveguesser">
            <Button
              pr={{ base: 2, md: 4 }}
              leftIcon={<Icon color="red.400" as={MdFavorite} />}
              variant="solid"
            >
              <Text display={{ base: "none", md: "block" }} fontWeight="bold">
                Sponsor
              </Text>
            </Button>
          </Link>
        </Stack>
      </Box>

      {/* TITLE and DATE */}
      <Center style={{ width: "100%" }}>
        <VStack
          mt={{ base: 3, md: 5 }}
          mb={12}
          direction="row"
          spacing={1}
          center="true"
        >
          <Text
            mt={{ base: 3, lg: 6 }}
            mb={{ base: 5, lg: 0 }}
            color="white"
            fontSize={{ base: "2xl", md: "4xl" }}
          >
            {gameTitle}
          </Text>
          <Text
            color="whiteAlpha.700"
            fontSize="medium"
            display={{ base: "none", lg: "block" }}
          >
            {"Date: " + date}
          </Text>
        </VStack>
      </Center>

      {/* BOARD */}
      <Center>
        <Box className="main" position="relative">
          <SimpleGrid columns={3} spacing={10}>
            <Box height="50px" textAlign="left">
              {accuracyText}% Accuracy
            </Box>
            <Box height="50px" textAlign="center">
              Combo x{comboText}
            </Box>
            <Box height="50px" textAlign="right">
              {scoreText}
            </Box>
          </SimpleGrid>

          <Box
            height={["sm", "md", "lg", "xl"]}
            width={["sm", "md", "lg", "xl"]}
          >
            <Chessboard
              height="100vw"
              width="100vw"
              position={fen}
              onPieceDrop={onMove}
              boardOrientation={boardOrientation ? "black" : "white"}
              customSquareStyles={customSquareStyles}
              customSquare={customSquare}
            />
          </Box>

          {/* PROMOTION UI */}
          <Box
            width="20vw"
            height="10vw"
            position="absolute"
            minWidth="100px"
            background="white"
            top="5vw"
            marginLeft="auto"
            marginRight="auto"
            left="0"
            right="0"
            zIndex="99999"
            borderRadius="10px"
            style={{
              maxWidth: "200px",
              maxHeight: "150px",
              textAlign: "center",
              cursor: "pointer",
              display: selectVisible ? "block" : "none",
            }}
          >
            <Stack direction="row">
              <Center>
                <Box role="presentation" onClick={() => promotion("q")}>
                  <img src={queen} alt="" style={{ width: 50 }} />
                </Box>
                <Box role="presentation" onClick={() => promotion("r")}>
                  <img src={rook} alt="" style={{ width: 50 }} />
                </Box>
                <Box role="presentation" onClick={() => promotion("b")}>
                  <img src={bishop} alt="" style={{ width: 50 }} />
                </Box>
                <Box role="presentation" onClick={() => promotion("n")}>
                  <img src={knight} alt="" style={{ width: 50 }} />
                </Box>
              </Center>
            </Stack>
          </Box>

          {/* AVATARS */}
          <Box
            mr={5}
            background=""
            position="absolute"
            left={{ base: "0%", lg: "115%" }}
            top={{ base: "-10%", lg: "15%" }}
            minWidth={{ base: "100%", lg: "200px" }}
            maxWidth={{ base: "100%", lg: "250px" }}
          >
            <Stack direction="row">
              <Center>
                <Box
                  mr={2}
                  borderRadius="10"
                  width="5vw"
                  height="5vw"
                  float="left"
                  backgroundColor="white"
                  style={{
                    backgroundImage: `url("images/portraits/${
                      pgnWinnerRef.current === "white" && !boardOrientation
                        ? Helper.toUrlPgnName(blackName)
                        : Helper.toUrlPgnName(whiteName)
                    }.jpg"), url(images/portraits/default-avatar.png)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    maxWidth: "60px",
                    maxHeight: "60px",
                  }}
                ></Box>
              </Center>
              <Stack direction={{ base: "row", md: "column" }}>
                <Text
                  textOverflow="ellipsis"
                  fontWeight="bold"
                  color="white"
                  fontSize={{ base: "14px", md: "17px" }}
                >
                  {pgnWinnerRef.current === "white" && !boardOrientation
                    ? blackName
                    : whiteName}
                </Text>
                <Text color="white">
                  {pgnWinnerRef.current === "white" && !boardOrientation
                    ? blackElo
                    : whiteElo}
                </Text>
              </Stack>
              <Text
                float="right"
                color="whiteAlpha.700"
                fontSize="medium"
                display={{ base: "block", lg: "none" }}
              >
                {date}
              </Text>
            </Stack>
          </Box>

          <Box
            width="20vw"
            height="10vw"
            mr={5}
            background=""
            position="absolute"
            left={{ base: "0%", lg: "115%" }}
            top={{ base: "105%", lg: "75%" }}
            minWidth={{ base: "100%", lg: "200px" }}
            maxWidth={{ base: "100%", lg: "250px" }}
          >
            <Stack direction="row">
              <Center>
                <Box
                  mr={2}
                  borderRadius="10"
                  width="5vw"
                  height="5vw"
                  float="left"
                  backgroundColor="white"
                  style={{
                    backgroundImage: `url("images/portraits/${
                      pgnWinnerRef.current === "white" && !boardOrientation
                        ? Helper.toUrlPgnName(whiteName)
                        : Helper.toUrlPgnName(blackName)
                    }.jpg"), url(images/portraits/default-avatar.png)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    maxWidth: "60px",
                    maxHeight: "60px",
                  }}
                ></Box>
              </Center>
              <Stack direction={{ base: "row", md: "column" }}>
                <Text
                  fontWeight="bold"
                  color="white"
                  textOverflow="ellipsis"
                  fontSize={{ base: "14px", md: "17px" }}
                >
                  {pgnWinnerRef.current === "white" && !boardOrientation
                    ? whiteName
                    : blackName}
                </Text>
                <Text color="white">
                  {pgnWinnerRef.current === "white" && !boardOrientation
                    ? whiteElo
                    : blackElo}
                </Text>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Center>

      {/* CONTROLS */}
      <Center>
        <VStack
          position="relative"
          pt={16}
          transform={{ base: "scale(0.9)", md: "scale(1)" }}
        >
          <Stack
            ml={-2}
            mb={2}
            direction="row"
            spacing={{ base: 2, md: 4 }}
            center="true"
          >
            <Button
              colorScheme="whiteAlpha"
              mr={0}
              onClick={() => setBoardOrientation(!boardOrientation)}
            >
              <Icon w={5} h={5} as={HiSwitchVertical} />
            </Button>
            <Button
              colorScheme="whiteAlpha"
              mr={2}
              onClick={() => setHint(movesVerbose[currentTurn]?.from)}
            >
              <Icon as={FaLightbulb} w={5} h={5} />
            </Button>
            <Button
              colorScheme="whiteAlpha"
              leftIcon={<Icon as={MdFileUpload} />}
              mr={2}
              onClick={onOpen}
            >
              Load PGN
            </Button>
            <Button
              colorScheme="whiteAlpha"
              mr={0}
              onClick={() => onShuffleClick()}
            >
              <Icon w={5} h={5} as={BiShuffle} />
            </Button>
          </Stack>

          {/* Toggle user color */}
          <Stack direction="row" mb={5}>
            <Button
              colorScheme={playerColor === "w" ? "teal" : "whiteAlpha"}
              onClick={() => {
                switchToWhite();
              }}
            >
              Play as White
            </Button>
            <Button
              colorScheme={playerColor === "b" ? "teal" : "whiteAlpha"}
              onClick={() => {
                switchToBlack();
              }}
            >
              Play as Black
            </Button>
          </Stack>

          {/* Skip Opening Button */}
          {skipOpeningVisible && (
            <Button colorScheme="pink" mb={5} onClick={skipOpening}>
              Skip Opening (First 5 Moves)
            </Button>
          )}
        </VStack>
      </Center>

      {/* FOOTER */}
      <Center>
        <Text mt={20} color="whiteAlpha.600">
          Copyright © 2021 MoveGuesser.com, All rights reserved.
        </Text>
      </Center>

      {/* LOAD PGN MODAL */}
      <Modal initialFocusRef={loadPgnRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent background="#1f2225" color="#d2d2d2">
          <ModalHeader>Load PGN</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              ref={loadPgnRef}
              value={pgnValue}
              onChange={handlePgnInputChange}
              placeholder="Paste the PGN"
            />
            <Checkbox
              mt={4}
              colorScheme="blue"
              isChecked={loadAsBlack}
              onChange={(e) => setLoadAsBlack(e.target.checked)}
            >
              Play as Black (White moves first)
            </Checkbox>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={loadPgnBtn}>
              Load
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Main;
